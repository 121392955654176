// @flow
//import NewsletterSignup from '../components/modals/newsletter.js'
// import Button from 'react-bootstrap/Button'
import React from 'react'
//import Carousel from '@brainhubeu/react-carousel';
//import '@brainhubeu/react-carousel/lib/style.css';
//import SocialIcons from '../static/social-icons.png'
// import AutoPlaySwiper from '../components/swiper'
import Rice from '../static/rice.png'
import BeeFlowers from '../static/propimages/beeflowers-intro.png'
import FlowerNumbersMatching from '../static/propimages/flower-number-matching.png'
import IceCreamCounting from '../static/propimages/icecreamcounting.png'
import Tray from '../static/propimages/tray.jpg'

function Home() {
//  const [modalShow, setModalShow] = React.useState(false);
  document.title = "Beeyond the Box Home";
  return (
  <div>
    <section id="welcome" className="section-welcome header">
      <div className="header__text-box">
        <h1 className="heading-primary">
          <span className="heading-primary--main">To learning... and BEEYOND!</span>
          <span className="heading-primary--sub">Thank You for visiting our site</span>
          <span className="heading-primary--sub">Click the Shop Now to see our monthly boxes and preorder for future months</span>
          <span className="heading-primary--sub">While there, check out our St. Ann Fundraiser boxes</span>
          <span className="heading-primary--sub">We donate $8.00 to St. Ann for each box purchased</span>
        </h1>
        <a className="button button--gunmetal button--animated" href="https://shop.beeyondthebox.com" target="_blank" rel="noreferrer">Shop Now</a>
      </div>
    </section>
    <section id="takealook" class="section-look">
      <div class="u-center-text u-margin-bottom-big">
          <h2 class="heading-secondary takealook">
              Take a look
          </h2>
      </div>
      <div class="row">
        <div class="col-1-of-2">
            <h3 class="heading-tertiary u-margin-bottom-small">Fun and education activities</h3>
            <p class="paragraph">
                You and your children will have fun doing all the activities, while teaching them about numbers, letters, and working on those oh so important fine motor skills!
            </p>

            <h3 class="heading-tertiary u-margin-bottom-small">Keep having fun</h3>
            <p class="paragraph">
                The majority of the activities in the box and be done over and over again with your children.  Our activity mats are laminated to protect them from accidental spills and for easier cleanup!  We make sure the fun keeps going on an on.
            </p>

            <a href="https://shop.beeyondthebox.com" target="_blank" rel="takealook" class="btn-text">Purchase a box in our store &rarr;</a>
        </div>
        <div class="col-1-of-2">
            <div class="composition">
                 <img srcset={`${Rice} 300w, ${Rice} 1000w`}
                      sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                      alt="Photo 2"
                      class="composition__photo composition__photo--p1"
                      src={Rice}/>

                <img srcset={`${IceCreamCounting} 300w, ${IceCreamCounting} 1000w`}
                      sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                      alt="Photo 3"
                      class="composition__photo composition__photo--p2"
                      src={IceCreamCounting}/>
               <img srcset={`${Tray} 300w, ${Tray} 1000w`}
                      sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                      alt="Photo 1"
                      class="composition__photo composition__photo--p3"
                      src={Tray}/>                      
            </div>
        </div>
    </div>
  </section>
  <section id="ourstory" className="section-about">
    <div className="u-center-text section-about__half">
      <h2 className="heading-secondary">
        Our Story
      </h2>
      <p className="paragraph">
        We are a wife and husband team who have adopted and are currently fostering children in our home.<br/>
        Like so many during the pandemic my wife became a teacher to our school aged foster child and a full-time activity planner for all our children.<br/>
        From her tireless work to keep our children healthy and full of fun that the idea was born to share her creativity and passion for helping children learn and play that we formed a small business to share her passion with the world.<br/>
        She designs from scratch all of the visuals, we print them, laminate them, buy all the supplies needed to do the activities, and pack them in boxes on our dining room table.<br/>
        When you order, we mail them out.. to you.. the parents that want to help their children have fun and learn at the same time.
      </p>
      <a href="https://shop.beeyondthebox.com" target="_blank" rel="about" class="btn-text--white">See what we have to offer &rarr;</a>
    </div> 


  </section>
  <section class="section-tours" id="section-tours">
    <div class="u-center-text u-margin-bottom-big">
      <h2 class="heading-secondary">
          Our Products
      </h2>
    </div>

    <div class="row">
      <div class="col-1-of-3">
        <div class="card">
          <div class="card__side card__side--front">
              <div class="card__picture card__picture--1">
                &nbsp;
              </div>
              <h4 class="card__heading">
                <span class="card__heading-span card__heading-span--1">May Activity Box</span>
              </h4>
              <div class="card__details">
                <ul>
                  <li>4 fun-filled activities</li>
                  <li>Instructions</li>
                  <li>Supplies</li>
                  <li>Free Shipping</li>
                </ul>
              </div>
          </div>
          <div class="card__side card__side--back card__side--back-1">
            <div class="card__cta">
              <div class="card__price-box">
                <p class="card__price-only">Starting At</p>
                <p class="card__price-value">$25.95</p>
              </div>
              <a href="#popup" class="btn btn--white">Buy Now!</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-1-of-3">
        <div class="card">
          <div class="card__side card__side--front">
            <div class="card__picture card__picture--2">
              &nbsp;
            </div>
            <h4 class="card__heading">
              <span class="card__heading-span card__heading-span--2">Summer Pack</span>
            </h4>
            <div class="card__details">
              <ul>
                <li>May, June and July Boxes</li>
                <li>4 fun-filled activities</li>
                <li>Instructions</li>
                <li>Supplies</li>
                <li>Free Shipping</li>
                <li>Save 20% over single boxes</li>
              </ul>
            </div>
          </div>
          <div class="card__side card__side--back card__side--back-2">
            <div class="card__cta">
              <div class="card__price-box">
                <p class="card__price-only">Starting At</p>
                <p class="card__price-value">$62.95</p>
              </div>
              <a href="#popup" class="btn btn--white">Buy Now!</a>
            </div>
          </div>
        </div>
      </div>
    <div class="col-1-of-3">
      <div class="card">
        <div class="card__side card__side--front">
          <div class="card__picture card__picture--3">
            &nbsp;
          </div>
          <h4 class="card__heading">
            <span class="card__heading-span card__heading-span--3">Fall Pack</span>
          </h4>
          <div class="card__details">
            <ul>
              <li>August, September and October Boxes</li>
              <li>4 fun-filled activities</li>
              <li>Instructions</li>
              <li>Supplies</li>
              <li>Free Shipping</li>
              <li>Save 20% over single boxes</li>
            </ul>
          </div>
        </div>
        <div class="card__side card__side--back card__side--back-3">
          <div class="card__cta">
            <div class="card__price-box">
              <p class="card__price-only">Starting At</p>
              <p class="card__price-value">$62.95</p>
            </div>
            <a href="#popup" class="btn btn--white">Buy Now!</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="u-center-text u-margin-top-huge">
      <a href="#" class="btn btn--green">Discover all our options!</a>
  </div>
</section>
</div>
  );
}

export default Home;
/**    <AutoPlaySwiper/>**/